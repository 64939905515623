import React from "react"
import styled from "styled-components"
import { FormButton } from "../components/Buttons/FormButtons"
import RoundTick from "../assets/forms/round-tick.svg"
import { Layout } from "../components/layout"
import { Link } from "gatsby"

const registersuccess = ({ data }) => {
  return (
    <Layout title={"Graduate Fashion Week - Register"}>
      <Container>
        <Tick />
        <h2 style={{ paddingTop: 20, textAlign: "center" }}>Thanks! You're now registered</h2>
        <p>We will notify you when our Graduate Fashion Week event takes place</p>
        <ButtonContainer>
          <Link to={"/"}>
          <FormButton >Go Home</FormButton>
          </Link>
        </ButtonContainer>
      </Container>
    </Layout>
  )
}

export default registersuccess

const Container = styled.div`
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  & h2 {
    margin-bottom: 15px;
    color: ${props => props.theme.colors.turquoise};
    @media (min-width: 1024px) {
      margin-bottom: 22px;
    }
  }
  & p {
    margin-bottom: 35px;
    color: ${props => props.theme.colors.turquoise};

    margin-top: 0 !important;

    @media (min-width: 1024px) {
      margin-bottom: 8px;
    }
  }
`
const ButtonContainer = styled.div`
  margin-top: 35px;
  @media (min-width: 1024px) {
    margin-bottom: 45px;
  }
`

const Tick = styled(RoundTick)`
  height: 100px;
  width: auto;
`
